var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.field.industrialStructParameterType === 'select_unique' ||
      _vm.field.industrialStructParameterType === 'select_multiple'
    )?_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('label',{attrs:{"for":("custom-field-" + (_vm.field.id))}},[_vm._v(" "+_vm._s(_vm.field.label)+" ")]),_c('SingleSelect',{class:_vm.field.industrialStructParameterType === 'select_multiple' ? 'custom-multiple' : '',attrs:{"id":("custom-field-" + (_vm.field.id)),"placeholder":_vm.$t('Select'),"optionSelected":_vm.verifySelectedOptions(),"options":_vm.field.industrialStructParameterOption.map(function (item) { return ({
          value: item.id,
          text: item.name
        }); }),"searchable":false,"translate":true,"state":_vm.field.required && _vm.hasError ? _vm.verifyValidFields(_vm.field) : null,"multiselect":_vm.field.industrialStructParameterType === 'select_multiple' ? true : false},on:{"input":function (value) { return _vm.alterValueInternal(value); }}},[_vm._v(" "+_vm._s(_vm.$t('RequiredField'))+" ")])],1):_vm._e(),(
      _vm.field.industrialStructParameterType === 'input_text' ||
      _vm.field.industrialStructParameterType === 'input_numeric'
    )?_c('b-col',{staticClass:"form-group input-block",attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.field.label,"label-for":("custom-field-" + (_vm.field.id)),"invalid-feedback":_vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":("custom-field-" + (_vm.field.id)),"value":_vm.fieldValueInternal,"placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.field.required && _vm.hasError ? _vm.verifyValidFields(_vm.field) : null,"type":_vm.field.industrialStructParameterType === 'input_numeric' ? 'number' : 'text'},nativeOn:{"input":function($event){return (function (e) {
            var newValue = _vm.applyMask(e.target.value);
            e.target.value = newValue;
            _vm.alterValueInternal(newValue);
          }).apply(null, arguments)}}})],1)],1):_vm._e(),(_vm.field.industrialStructParameterType === 'switch')?_c('b-col',{staticClass:"custom-switch-button",attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-checkbox',{staticClass:"check-button p-0",attrs:{"id":_vm.field.name,"name":"resource-check-button","switch":""},on:{"change":function (value) { return _vm.alterValueInternal(value); }},model:{value:(_vm.field.value),callback:function ($$v) {_vm.$set(_vm.field, "value", $$v)},expression:"field.value"}}),_c('div',{staticClass:"text-allow"},[_vm._v(_vm._s(_vm.$t(_vm.field.name)))])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }